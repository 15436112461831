import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components'

import { container, padding, bgImage, bgIcon, hoverState, content, pageTitle } from '../styles/global';
import { media } from '../styles/utils';
import { maroon } from '../styles/colors';

const VenueMenu = ({pageContext, ...props}) => {
	const data = JSON.parse(pageContext.acf_json)

	const renderImage = () => {
		if (!data.image) return;

		return (
			<Image
				image={data.image.sizes.medium2}
			/>
		)
	}

	const renderMenu = () => {
		const items = data.menu_links && data.menu_links.map((item, i) => {
			return (
				<MenuLink
					href={item.pdf_file}
					target={'_blank'}
				>
					{item.title}
					<Arrow/>
				</MenuLink>
			)
		})

		return (
			<Menu>
				<Subheading
					dangerouslySetInnerHTML={{__html: data.description}}
				/>

				{items}
			</Menu>
		)
	}

	return (
		<Layout
			meta={data && data.seo}
			menu={pageContext.menu}
		>
			<Container>
				<Heading>{pageContext.sectionTitle}</Heading>
				<Content>
					{renderImage()}
					{renderMenu()}
				</Content>
			</Container>
		</Layout>	
	)
}


// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const Image = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage}
`

// Layout

const Container = styled.div`
	${container}
	${padding}
    flex-direction: column;
	align-items: center;
	flex: 1;

	> ${Heading} {
		${pageTitle}
	}
`

const Content = styled.div`
	${content}

	> ${Image} {
		width: 100%;
		max-width: 460px;
		height: 320px;
		
		${media.phone`
			height: 198px;
		`}
	}
`

// Menu

const Arrow = styled.div`
	background-image: url(${require('../assets/icons/menu-arrow.svg')});
	${bgIcon}
	width: 18px;
	height: 10px;
	margin-left: 8px;
	transform: translateY(2px);
`

const MenuLink = styled.a`
	display: flex;
	align-items: center;

	font-size: 22px;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		color: ${maroon}
	}

	${media.phone`
		font-size: 16px;
		line-height: 18px;
	`}
`

const Menu = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 65px;

	${Subheading} {
		font-size: 16px;
		line-height: 16px;
		text-transform: uppercase;
		font-weight: bold;
	}

	${media.phone`
		margin-top: 31px;

		${Subheading} {
			margin-bottom: 10px;
		}
	`}
`


export default VenueMenu

